import { css, html, LitElement } from 'lit';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/button/button-subtle';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/menu/menu-item-radio.js';
import '@brightspace-ui/core/components/list/list-item-content.js';

import '../components/environment.js';
import { fuzzyDate } from '../helpers/date.js';
import {getDeploymentStatusIndicator, getDisplayName} from '../helpers/helpers.js';

class Home extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      _selectedUsers: { type: Array },
      _allUsers: { type: Array },
      _sessionUser: {type: Object},
      _preferences: {type: Object},
      _selectedEnvironments: { type: Array },
      _environments: { type: Array },
      _tenants: { type: Array },
      _loginEnabled : {type: Boolean},
      _selectedTenant: {type: Object},
      _selectedEnvironment: {type: Object},
      _fussUsers: {type: Array},
      loading: {type: Boolean}
    };
  }

  static get styles() {
    return [
      heading1Styles,
      selectStyles,
      css`
          :host {
            display: block;
          }
          .environment {
            margin: 10px 0;
          }
          .add-environment-button {
            display: inline-block;
          }
          .environments-header {
            display: inline-block;
          }

          .user-header {
            display: flex;
            margin-left :auto;
          }

          .header {
            display: flex;
            margin-top: 10px
          }

          .user-content {
            margin: 10px
          }

          .remove-environment{
            margin-left:10px;
          }
          .selected-accordion{
            margin: auto;
            width:100%;
          }
          .persona-heading {
            width: 100%;
          }
          .persona-section{
            display:flex;
            flex-flow: row wrap;
            margin-bottom:35px;
          }
          .persona-option {
            margin-left:10px;
            width:auto;
            max-width: 30%;
          }

          .d2l-input-select{
            max-width:100%;
          }

          .login{
            display:flex;
            margin:auto auto 2px 50px;
          }

          .section-header {
            display:flex;
            width:100%;
          }

          .add-button{
            margin: auto 10px auto auto;
          }

    `];
  }

  constructor() {
    super();
    this._allUsers = [];
    this._fussUsers = [];
    this._tenants = [];
    this._loginEnabled = false;
    this._selectedTenant = {
      'value' : null,
      'displayName': 'Select A Tenant'
    }
    this.loading = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    super.firstUpdated();
    try {
      this._sessionUser = await this.client.fetchSession();
      await this._fetchOrCreatePreferences();
    } catch(err) {
      console.log('Error fetching session', err)
    }
    this._allUsers = await this.client.fetchUsers();
    this._environments = await this.client.fetchEnvironments();
    this.loading = false;
  }

  updated() {
    const userId = this.shadowRoot.getElementById("persona-user")?.value;
    const environmentId = this.shadowRoot.getElementById("persona-environment")?.value;
    const tenantId = this._selectedTenant.value;
    //Update Tenants list
    const selectedEnv = this._environments?.find( x => x.id === environmentId);
    this._tenants = selectedEnv?.tenants;

    //Enable login
    if(userId && environmentId && tenantId) {
      this._loginEnabled = true;
    } else {
      this._loginEnabled = false;
    }
  }

  get environmentDialog() {
    if (!this._environmentDialog) {
      this._environmentDialog = this.shadowRoot.getElementById('show-environment-dialog');
    }
    return this._environmentDialog;
  }

  get usersDialog() {
    if (!this._usersDialog) {
      this._usersDialog = this.shadowRoot.getElementById('show-users-dialog');
    }
    return this._usersDialog;
  }

  _openAddEnvironmentDialog() {
    this.environmentDialog.opened = true;
    this.environmentDialog.resize();
  }

  _openAddUsersDialog() {
    this.usersDialog.opened = true;
  }

  async _fetchOrCreatePreferences() {
    this._preferences = await this.client.fetchPreferences();
    if(!this._preferences) {
      this._selectedEnvironments = [];
      this._selectedUsers = [];
      this._preferences = {
        environments: this._selectedEnvironments,
        users: this._selectedUsers
      }

      await this.client.updatePreferences(this._preferences);
    } else {
      this._selectedEnvironments = this._preferences.environments || [];
      this._selectedUsers = this._preferences.users || [];
    }
  }

   _addEnvironmentToFavourites(id, displayName) {
    this._selectedEnvironments.push({id, displayName});
    this._updatePreferences();
  }

  _removeEnvironmentFromFavourites(id, displayName) {
    this._selectedEnvironments = this._selectedEnvironments.filter( x => x.id != id && x.displayName != displayName);
    this._updatePreferences();
  }

  _addUserToFavourites(id, displayName) {
    this._selectedUsers.push({id, displayName});
    this._updatePreferences();
  }

  _removeUserFromFavourites() {
    const currectSelectedUser = this.shadowRoot.getElementById("persona-user");

    if(currectSelectedUser) {
      const id = currectSelectedUser.value;
      const displayName = currectSelectedUser.text;
      this._selectedUsers = this._selectedUsers.filter( x => x.id != id && x.displayName != displayName);
      this._updatePreferences();
    }
  }

  _updatePreferences() {
    this._preferences = {
      environments: this._selectedEnvironments,
      users: this._selectedUsers
    }

    this.client.updatePreferences(this._preferences);
  }

  _addEnvironmentDialogTemplate() {
    const selectedEnvironments = this._selectedEnvironments?.map( s => s.id) || [];
    let environments = this._environments || [];
    environments = environments.filter( e => !selectedEnvironments.includes(e.id));

    return html`
      <d2l-dialog id="show-environment-dialog" title-text="Add environment to home">
        <d2l-list>
      ${environments.map(environment => html`
            <d2l-list-item label="${environment.displayName}">
              <d2l-list-item-content>
                <div>
                  <d2l-button-subtle @click="${() => this._addEnvironmentToFavourites(environment.id, environment.displayName)}"  icon="tier1:add"></d2l-button-subtle>
                  Add ${environment.displayName} to home
                </div>
              </d2l-list-item-content>
            </d2l-list-item>
          `)}
         </d2l-list>
      </d2l-dialog>`;
  }

  _addUserDialogTemplate() {
    const selectedUsers = this._selectedUsers?.map( s => s.id) || [];
    let users = this._allUsers || [];
    users = users.filter( u => !selectedUsers.includes(u.email));

    return html`
      <d2l-dialog id="show-users-dialog" title-text="Add users to home">
        <d2l-list>
        ${users.map(user => html`
            <d2l-list-item label="${getDisplayName(user)}">
              <d2l-list-item-content>
                <div>
                  <d2l-button-subtle @click="${() => this._addUserToFavourites(user.email, getDisplayName(user))}"  icon="tier1:add"></d2l-button-subtle>
                  Add ${getDisplayName(user)} to favourites dropdown
                </div>
              </d2l-list-item-content>
            </d2l-list-item>
          `)}
         </d2l-list>
      </d2l-dialog>`;
  }

  async _personaEnvironmentChanged(e) {
    this._selectedEnvironment = this._environments.find( x => x.id === e.target.value);
    this._tenants = this._selectedEnvironment.tenants;

    if (this._selectedEnvironment?.fussUrl && this._selectedTenant) {
      this._fussUsers = await this.client.fetchTenantUsers(this._selectedEnvironment.id, this._selectedTenant.value);
    }
  }

  async _setSelectedTenant(e) {
    this._selectedTenant = {
      'value' : e.target.value,
      'displayName': e.target.text
    }

    if (this._selectedEnvironment?.fussUrl && this._selectedTenant) {
      this._fussUsers = await this.client.fetchTenantUsers(this._selectedEnvironment.id, this._selectedTenant.value);
    }
  }

  _getFormattedUser() {
    const displayName = this._sessionUser ? this._sessionUser.nameID : 'Unknown User';

    return {
      displayName: displayName
    }
  }

  _getSelectedEnvironmentsFull() {
    const selectedEnvironments = this._selectedEnvironments?.map( s => s.id) || [];
    return this._environments?.filter(x => selectedEnvironments.includes(x.id));
  }

  _userClicked(newWindow = false) {
    return async e => {
      const userId = this.shadowRoot.getElementById("persona-user")?.value;
      const environmentId = this.shadowRoot.getElementById("persona-environment")?.value;
      const tenantId = this._selectedTenant.value;

      if(!userId || !environmentId || !tenantId){
        return;
      }

      e.stopPropagation();
      const href = await this._getLoginLink(environmentId, tenantId, userId);

      if (!href) {
        return;
      }

      if (newWindow) {
        window.open(href);
      } else {
        location.href = href;
      }

    };
  }

  async _getLoginLink(environmentId, tenantId, userId) {
    const tenant = await this.client.fetchTenantSSO(environmentId, tenantId);
    const domain = tenant.ssoDomains.find(x => x.default) || tenant.ssoDomains[0];
    if (domain.loginType === 'magic-link') {
      try {
        return await this.client.fetchLoginLink(environmentId, tenantId, userId, domain.host);
      } catch (err) {
        this.client.createAlert('error', 'Invalid email address for tenant');
        return null;
      }
    }
    const host = this.client.isDev() ? 'http://localhost:3000' : `https://${location.hostname}`
    return `${host}/api/sso/login/${environmentId}/${tenantId}?username=${userId}&host=${domain.host}`;
  }

  render() {
    if(this.loading) return html``;
    const environments = this._getSelectedEnvironmentsFull() || this._environments || [];
    let users = [];
    this._selectedEnvironment = this._selectedEnvironment || environments[0];
    if (this._selectedEnvironment?.fussUrl) {
      users = this._fussUsers.map(user => ({ ...user, id: user.guid }));
    } else {
      users = this._selectedUsers || this._allUsers || [];
    }

    const tenants = this._tenants || [];
    // sort tenants by displayName
    tenants.sort((a, b) => a.displayName.localeCompare(b.displayName));
    const groupedtenants = tenants.reduce(
      (entryMap, e) => entryMap.set(e.type, [...entryMap.get(e.type)||[], e]),
      new Map()
    );

    const sessionUser = this._getFormattedUser();
    return html`
        <div class="header">
          <h1 class="d2l-heading-1 environments-header user-content">Home</h1>
          <div class="user-header">
            <profile-image .user="${sessionUser}" slot="illustration" class="user-content"></profile-image>
          </div>
        </div>
        <div class="persona-section">
          <div class="section-header">
            <h3 class="d2l-heading-3 persona-heading">Personas</h3>
            <d2l-button-subtle @click="${this._removeUserFromFavourites}" icon="tier1:close-circle" class="add-button" text="Remove currently selected user"></d2l-button-subtle>
            <d2l-button-subtle @click="${this._openAddUsersDialog}" icon="tier1:add" text="Add user to dropdown" class="add-button"></d2l-button-subtle>
          </div>
          <div class="persona-option">
            <label for="persona-user">User:</label><br>
            <select id="persona-user" class="d2l-input-select">
              ${users.map(user => html`
                <option value=${user.id}>
                  ${getDisplayName(user)}
                </option>
              `)}
            </select>
          </div>
          <div class="persona-option">
            <label for="persona-environment">Environment:</label><br>
            <select id="persona-environment" class="d2l-input-select" @change="${this._personaEnvironmentChanged}">
              ${environments.map(environment => html`
                <option value=${environment.id}>
                  ${environment.displayName}
                </option>
              `)}
            </select>
          </div>
          <div class="persona-option">
            <label for="persona-tenant" >Tenant:</label><br>
            <d2l-dropdown-button text="${this._selectedTenant.displayName}">
            <d2l-dropdown-menu>
              <d2l-menu label="Tenant">
              ${Array.from(groupedtenants.keys()).map(key => {
                return html`
                <d2l-menu-item text="${key}">
                  <d2l-menu>
                    ${groupedtenants.get(key)?.map( t => {
                      return html `<d2l-menu-item-radio text="${t.displayName}" value="${t.id}" @d2l-menu-item-change="${this._setSelectedTenant}"></d2l-menu-item-radio>`
                    })}
                  </d2l-menu>
                </d2l-menu-item>`
              })}
            </d2l-dropdown-menu>
            </d2l-dropdown-button>
          </div>
          <div class="login">
            <d2l-button @click="${this._userClicked()}" primary class="login-button" ?disabled=${!this._loginEnabled}>Login</d2l-button>
            <d2l-button-icon @click="${this._userClicked(true)}" icon="tier1:new-window" ?disabled=${!this._loginEnabled}></d2l-button-icon>
          </div>
        </div>
        <div class="section">
          <div class="section-header">
            <h3 class="d2l-heading-3">Environments</h3>
            <d2l-button-subtle @click="${this._openAddEnvironmentDialog}" icon="tier1:add" text="Add environment to home page" class="add-button"></d2l-button-subtle>
          </div>

          <d2l-labs-accordion>
              ${environments.map(environment => html`
              <d2l-collapsible-panel panel-title=${environment.displayName} class="environment">
                  <d2l-collapsible-panel-summary-item slot="summary">
                      <span class="deploy-date">${getDeploymentStatusIndicator(environment.latestDeployment, 'Deployment: ')} ${environment.latestDeployment.date ? html `- ${fuzzyDate(environment.latestDeployment.date)}` : ''}</span>
                  </d2l-collapsible-panel-summary-item>
                  <pm-environment class="environment" environment-id="${environment.id}"></pm-environment>
                  <d2l-button-icon slot="actions" @click="${() => this._removeEnvironmentFromFavourites(environment.id, environment.displayName)}" icon="tier1:close-circle" class="remove-environment" text="Remove"></d2l-button-icon>
                </d2l-collapsible-panel>

              `)}
          </d2l-labs-accordion>
        </div>
        ${this._addEnvironmentDialogTemplate()}
        ${this._addUserDialogTemplate()}
      `;
  }

}

window.customElements.define('pm-home', Home);
