import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/tabs/tabs';
import '@brightspace-ui/core/components/tabs/tab-panel';

import '@brightspace-ui/core/components/demo/code-view';
import 'prismjs/components/prism-json';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin';

class PMIDPInfo extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      _idpData: { type: Object },
      entityId: { type: String, attribute: 'entity-id' },
      environmentId: { type: String, attribute: 'environment-id' },
      disableJson: { type: Boolean, attribute: 'disable-json' },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading1Styles,
      css`
          :host {
            display: block;
          }
          .environment {
            padding: 10px 0;
          }
        .loading-placeholder {
           width: 100%;
           height: 100px;
        }
        d2l-code-view {
          max-width: 100%;
        }
    `];
  }
  constructor() {
    super();
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    this._idpDataXml = await this.client.fetchIDPMetadata(this.environmentId, this.entityId);
    this._idpDataJson = await this.client.fetchIDPMetadata(this.environmentId, this.entityId, 'dynamo');
    this.skeleton = false;
  }

  render() {
    if (this.skeleton) return html`<div class="d2l-skeletize loading-placeholder"></div>`;
    return html`
      <d2l-tabs class="d2l-skeletize">
        ${this.disableJson ? html`` : html`
        <d2l-tab-panel text="Dynamo JSON">
            <d2l-code-view class="d2l-skeletize" language="json">${this._idpDataJson}</d2l-code-view>
        </d2l-tab-panel>
        `}
        <d2l-tab-panel text="Metadata XML">
          <d2l-code-view class="d2l-skeletize" language="xml">${this._idpDataXml}</d2l-code-view>
        </d2l-tab-panel>
      </d2l-tabs>
      `;
  }

}

window.customElements.define('pm-idp-info', PMIDPInfo);
