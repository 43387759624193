import {css, html, LitElement, nothing} from 'lit';

import '@brightspace-ui/core/components/dialog/dialog';

import '@brightspace-ui/core/components/icons/icon';
import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/button/button-subtle';

import '@brightspace-ui/core/components/list/list';
import '@brightspace-ui/core/components/list/list-item';
import '@brightspace-ui/core/components/list/list-controls';

import '@brightspace-ui/core/components/tabs/tabs';
import '@brightspace-ui/core/components/tabs/tab-panel';

import '@brightspace-ui/core/components/form/form';
import '@brightspace-ui/core/components/inputs/input-text';

import { v4 as uuid } from 'uuid';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { inputTemplate, mapify, tenantFormAttributes, tenantTypeIndicator } from '../helpers/helpers';
import { cardStyles } from '../styles/card';

import '../components/profile-image';
import '../components/users-list';
import {redirect} from "d2l-router";

class PMTenant extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      environmentId: { type: String, attribute: 'environment-id' },
      _domain: { type: String },
      _newDomain: { type: Object },
      _tenant: { type: String },
      _tenantId: { type: String },
      _formType: { type: String },
      _userDialog: { type: Boolean },
      allUsers: { type: Array },
      tenantUsers: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading1Styles,
      cardStyles,
      selectStyles,
      inputLabelStyles,
      css`
        :host {
          display: block;
        }
        .d2l-heading-2 {
          margin-bottom: 0;
        }
        .submit-tenant {
          padding-top: 10px;
        }
      `];
  }

  constructor() {
    super();
    this._tenant = {};
    this._newDomain = {};
    this.skeleton = true;
    this._domainsMap = [];
    this.tenantUsers = [];
    this.allUsers = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  get currentDomain() {
    return this._domainsMap[this._domain] || {};
  }

  async firstUpdated() {
    this.environment = await this.client.fetchEnvironment(this.environmentId);
    if(this.environment?.fussUrl) {
      // include the current query params in the redirect
      redirect(`/tenants/${this.environmentId}/${this.tenantId}/fuss?${window.location.search}`)
    }
    this.allUsers = await this.client.fetchUsers();
    this._tenant = await this.client.fetchTenantSSO(this.environmentId, this.tenantId);
    this._domain = (this._tenant.ssoDomains.find(x => x.default) || this._tenant.ssoDomains[0] || {}).ssoId;
    this._domainsMap = mapify(this._tenant.ssoDomains, 'ssoId');
    this._existingURLs = this._tenant.ssoDomains.map(domain => domain.domain);
    this._tenantId = this.tenantId;
    this._newDomain = {
      tenantId: this.tenantId,
      admin: false,
      ssoId: uuid(),
      host: `<prefix>.${this.environmentId}.nova.dev.brightspace.com`,
      allowableDomains: 'd2l.com, d2lmail.onmicrosoft.com',
    };
    this.skeleton = false;
  }

  get tenantDialog() {
    if (!this._tenantDialog) this._tenantDialog = this.shadowRoot.getElementById('show-tenant-dialog');
    return this._tenantDialog;
  }

  _openAddTenantDomainDialog() {
    this.tenantDialog.opened = true;
  }

  _addTenantDomainDialogTemplate() {
    return html`
      <d2l-dialog id="show-tenant-dialog" title-text="Create tenant domain">
        <d2l-form id="tenantForm" @change="${this._changeValue(this._newDomain)}">
          ${tenantFormAttributes.map(attr => inputTemplate(attr, this._newDomain))}
        </d2l-form>
        <d2l-input-checkbox
          id="checkbox"
          ?checked=${this._newDomain.admin}
          @change=${this._changeAdmin()}>
          Admin Domain
        </d2l-input-checkbox>
        <d2l-input-text id="id" label="Allowable Email Domain (comma-separated)" type="text" @change=${this._changeAllowableDomains()} value="${this._newDomain.allowableDomains}" required></d2l-input-text>
        <d2l-button slot="footer" @click="${this._createTenantDomain}" primary data-dialog-action="done">Submit</d2l-button>
        <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
      </d2l-dialog>`;
  }

  _tenantInfoTemplate() {
    return html`
      <d2l-form id="tenantForm" @change="${this._changeValue(this.currentDomain)}">
        ${tenantFormAttributes.map(attr => inputTemplate(attr, this.currentDomain, { formType: 'edit' }))}
        <d2l-button class="submit-tenant" @click="${this._submitTenantDomainForm}" primary data-dialog-action="done">Save</d2l-button>
      </d2l-form>
    `;
  }

  _changeDomain(e) {
    this._domain = e.target.value;
  }

  _changeAdmin() {
    return async e => {
      this._newDomain.admin = e.target.checked;
    };
  }

  _changeAllowableDomains() {
    return async e => {
      this._newDomain.allowableDomains = e.target.value;
    };
  }

  _changeValue(base) {
    return e => {
      if (e.target.id) {
        const splitId = e.target.id.split('_')[0];
        base[splitId] = e.target.value;
        this.update();
      }
    };
  }

  async _submitTenantDomainForm() {
    this.client.updateTenantSSO(this.environmentId, this.currentDomain);
  }

  async _createTenantDomain() {
    // validate URL is unique
    const isValid = await this.client.isValidDomain(this.environmentId, this._newDomain.host);
    if (!isValid) {
      this.client.createAlert('error', 'This Domain URL is already in use. The record was not saved.');
      return;
    }
    this._newDomain.allowableDomains = this._newDomain.allowableDomains.split(',').map(word => { return word.trim(); });
    this.client.updateTenantSSO(this.environmentId, this._newDomain);
    this._newDomain = {
      tenantId: this.tenantId,
      admin: false,
      ssoId: uuid(),
      host: `<prefix>.${this.environmentId}.nova.dev.brightspace.com`,
      allowableDomains: 'd2l.com, d2lmail.onmicrosoft.com',
    };
  }

  render() {
    const tenantTab = this.tenantUsers.length > 0 ?
    html`<d2l-tab-panel text="Tenant users">
      <pm-users-list readonly .users="${this.tenantUsers}" relay-state="${this.relayState}" tenant-id="${this._tenantId}" environment-id="${this.environmentId}" login-type="${this.currentDomain.loginType}" domain=${this.currentDomain.host}></pm-users-list>
    </d2l-tab-panel>` : nothing;

    const relayState = this.relayState || '/';
    return this._tenant.ssoDomains ? html`
      <h1 class="d2l-heading-1 d2l-skeletize">${this.environmentId} - ${this._tenant.displayName} - ${tenantTypeIndicator(this._tenant.type)}</h1>
      <label for="domain-select">Choose a domain:</label>
      <select
        id="domain-select"
        aria-label="Choose a domain:"
        value=${this._domain}
        @change=${this._changeDomain}
        class="d2l-input-select">
          ${repeat(this._tenant.ssoDomains, sso => sso.ssoId, sso => html`
            <option .selected=${sso.ssoId === this._domain} .value=${sso.ssoId}>${sso.domain}</option>
          `)}
      </select>
      <d2l-button-subtle text="Add tenant Domain" icon="tier1:add" @click="${this._openAddTenantDomainDialog}"></d2l-button-subtle>
      <d2l-tabs class="d2l-skeletize">
        ${tenantTab}
        <d2l-tab-panel text="Users">
            <pm-users-list .users="${this.allUsers}" relay-state="${relayState}" tenant-id="${this._tenantId}" environment-id="${this.environmentId}" login-type="${this.currentDomain.loginType}" domain=${this.currentDomain.host}></pm-users-list>
        </d2l-tab-panel>
        <d2l-tab-panel text="Tenant Domain Info">
          ${this._tenantInfoTemplate()}
        </d2l-tab-panel>
        <d2l-tab-panel text="IDP info">
          <pm-idp-info entity-id="${this._tenantId}" environment-id="${this.environmentId}"></pm-idp-info>
        </d2l-tab-panel>
      </d2l-tabs>
      ${this._addTenantDomainDialogTemplate()}
      ` : html``;
  }

}

window.customElements.define('pm-tenant', PMTenant);
