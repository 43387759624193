/**
 * TODO: Figure out a better way of abstracting out these functions. This helper makes it seem like the component structure isn't quite right.
 */

import '@brightspace-ui/core/components/status-indicator/status-indicator';
import { html } from 'lit';

export const deployFormAttributes = [
  { id: 'branchName', label: 'Branch name', required: true, disabled: () => false },
];

export const tenantFormAttributes = [
  { id: 'loginType', label: 'Login type', required: true, disabled: () => true, type: 'multi-select', attributes: [{ label: 'Magic link', value: 'magic-link' }, { label: 'SAML', value: 'saml' }] },
  { id: 'tenantId', label: 'Tenant ID', required: true, disabled: () => true },
  { id: 'displayName', label: 'Display name', required: true, disabled: () => false },
  { id: 'host', label: 'Sign on URL', required: true, disabled: type => type === 'edit' },
  { id: 'ssoId', label: 'SSO ID', required: true, disabled: () => true },
];
export const userFormAttributes = [
  { id: 'userId', label: 'User ID', required: true, disabled: type => type !== 'add' },
  { id: 'displayName', label: 'Display name', required: false, disabled: () => false },
  { id: 'employeeId', label: 'Employee ID', required: false, disabled: () => false },
  { id: 'firstName', label: 'First name', required: false, disabled: () => false },
  { id: 'lastName', label: 'Last name', required: false, disabled: () => false },
  { id: 'email', label: 'Email', required: false, disabled: () => false },
  { id: 'title', label: 'Title', required: false, disabled: () => false },
  { id: 'department', label: 'Department', required: false, disabled: () => false },
  { id: 'managerName', label: 'Manager name', required: false, disabled: () => false },
  { id: 'managerId', label: 'Manager ID', required: false, disabled: () => false },
  { id: 'win', label: 'WIN', required: false, disabled: () => false },
];

export const inputTemplate = (attr, base, opts = { attrSuffix: 'attr', formType: 'add' }) => {
  const { attrSuffix, formType } = opts;
  const id = `${attr.id}_${attrSuffix}`;
  if (attr.type === 'multi-select') {
    return html`
        <label class="d2l-input-label" for="${name}">${attr.label}</label>
        <select id="${id}" name="${name}" class="d2l-input-select" .value="${base[attr.id]}">
          <option value="">-- Select ${attr.label} --</option>
          ${attr.attributes.map(a => html`
            <option value="${a.value}" .selected=${base[attr.id] === a.value}>${a.label}</option>
          `)}
        </select>
      `;
  }
  return html`<d2l-input-text ?required="${attr.required}" ?disabled="${attr.disabled(formType)}" label="${attr.label}" name="${id}" id="${id}" type="text" value="${base[attr.id]}"></d2l-input-text>`;
};

export const mapify = (array, id) => {
  const result = {};
  if (!array) return result;
  array.map(item => {
    result[item[id]] = item;
  });
  return result;
};

export const tenantLoginTypeIndicator = type => {
  return type === 'saml' ?
    html`
              <d2l-status-indicator style="height: fit-content" state="default" text="SAML"></d2l-status-indicator>
            ` :
    html`
              <d2l-status-indicator style="height: fit-content" state="none" text="Magic link"></d2l-status-indicator>
            `;

};

export const tenantTypeIndicator = type => {
  return type === 'provider' ?
    html`
              <d2l-status-indicator style="height: fit-content" state="default" text="provider"></d2l-status-indicator>
            ` : type === 'employer' ?
      html`
                <d2l-status-indicator style="height: fit-content" state="none" text="employer"></d2l-status-indicator>
              ` :
      html`
        <d2l-status-indicator style="height: fit-content" state="none" text="admin"></d2l-status-indicator>
      `;

};

export const getDeploymentStatusIndicator = (deployment, prefix) => {
  switch(deployment?.status) {
    case 'complete':
      return html`<d2l-status-indicator style="height: fit-content" state="success" text="${prefix}Completed"></d2l-status-indicator>`;
    case 'failure':
      return html`<d2l-status-indicator style="height: fit-content" state="critical" text="${prefix}Failure"></d2l-status-indicator>`;
    case 'unknown':
      return html`<d2l-status-indicator style="height: fit-content" state="default" text="${prefix}Unknown"></d2l-status-indicator>`;
    default:
      return html`<d2l-status-indicator style="height: fit-content" state="none" text="${prefix}Pending"></d2l-status-indicator>`;
  }
}

export const getDisplayName = user => {
  const firstLast = `${user.firstName} ${user.lastName}`;
  return user.displayName || firstLast || user.email || user.thirdPartyUserId || user.guid || 'Unknown';
};
