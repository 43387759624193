import { html, LitElement } from 'lit';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

const columns = ['Successful', 'Errors', 'Total'];

const data = [
  { origin: 'Wave', data: { 'successful': 100, 'errors': 10, 'total': 110 }, selected: false },
  { origin: 'BambooHR', data: { 'successful': 230, 'errors': 20, 'total': 220 }, selected: false },
  { origin: 'Kraken', data: { 'successful': 4145, 'errors': 41, 'total': 4185 }, selected: false },
];

class FussSyncTable extends LitElement {

  static get styles() {
    return tableStyles;
  }

  render() {
    const type = this.type === 'light' ? 'light' : 'default';

    return html`
        <d2l-table-wrapper>
          <table class="d2l-table">
            <thead>
              <tr>
                <th>Origin</th>
                ${columns.map((data) => html`<th>${data}</th>`)}
              </tr>
            </thead>
            <tbody>
              ${data.map((row) => html`
                <tr>
                  <th>${row.origin}</th>
                  ${columns.map((column) => html`<td>${row.data[column.toLowerCase()]}</td>`)}
                </tr>
              `)}
            </tbody>
          </table>
        </d2l-table-wrapper>
      `;
  }

}
customElements.define('pm-fuss-sync-table', FussSyncTable);
