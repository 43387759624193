import { css, html, LitElement } from 'lit';
import {getDisplayName} from "../helpers/helpers.js";

const PROFILE_IMAGE_COLOURS = [
  '#8b271f',
  '#c74f05',
  '#527f1f',
  '#346633',
  '#165f5b',
  '#0c7683',
  '#3155bf',
  '#6f6bb8',
  '#9860af',
  '#804167',
  '#d13b7f',
  '#3b4148',
];

class ProfileImage extends LitElement {

  static get properties() {
    return {
      user: { type: Object },
      /**
       * The attribute to display in the metata
       * @type {'department'|'title'|'none'}
       * @default "title"
       */
      metaAttribute: { type: String, reflect: true, attribute: 'meta-attribute'},
      /**
       * Size of the user text
       * @type {'small'|'large'|'hidden'}
       * @default "small"
       */
      textSize: { type: String, attribute: 'text-size' },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          min-width: 350px
        }
        .profile-wrapper {
          display: flex;
          align-items: center;
        }

        .profile-placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 42px;
          min-width: 42px;
          height: 42px;
          margin: 0;
          color: var(--d2l-color-regolith);
          text-align: center;
          background-color: var(--background);
          border-radius: 6px;
        }

        .small-text {
          font-size: 0.7rem;
        }

        .large-text {
          font-size: 0.9rem;
        }

        .profile-name-text,
        .profile-meta-text {
          flex: 0 1 auto;
          width: 100%;
          min-width: 40px;
          line-height: 1.6;
        }

        .profile-name-text {
          margin-right: 0;
          font-size: 18px;
        }

        :host([metaAttribute='department']) .profile-name-text {
          font-weight: bold;
        }

        .profile-meta-text {
          font-size: 0.6rem;
        }

        .profile-text-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          margin-right: 10px;
          margin-left: 10px;
        }

        @media (max-width: 1024px) {
          .small-text {
            display: none;
          }

          .large-text {
            display: none;
          }

          .profile-meta-text {
            display: none;
          }
        }
    `];
  }

  constructor() {
    super();
    this.metaAttribute = 'title';
  }

  firstUpdated() {
    if (!this.textSize) this.textSize = 'small';
    this.style.setProperty('--background', `${this._userToColour(getDisplayName(this.user))}`);
  }

  render() {
    if (!this.user) {
      return html``;
    }
    const displayName = getDisplayName(this.user);
    const initials = this._extractInitials(displayName);
    let metaText = this.user[this.metaAttribute];
    return html`
      <span class="profile-wrapper">
        <div class="profile-placeholder" aria-hidden="true">${initials}</div>
        ${this.textSize !== 'hidden' ? html`
        <div class="profile-text-wrapper">
          <div class="profile-name-text ${this.textSize}-text">${displayName}</div>
          ${this.metaAttribute !== 'none' ? html`
          <div class="profile-meta-text">${metaText}</div>
          ` : html``}
        </div>
        ` : html``}
      </span>
    `;
  }

  _extractInitials(user) {
    const nameArray = user.indexOf('@') >= 0 ? user.split('@')[0].split('.') : user.split(' ');
    return (nameArray.length > 1 ? `${nameArray[0][0]}${nameArray[1][0]}` : `${nameArray[0][0]}`).toUpperCase();
  }

  _hashStr(user) {
    let hash = 0;
    for (let i = 0; i < user.length; i++) {
      hash += user.charCodeAt(i);
    }
    return hash;
  }

  _userToColour(user) {
    const hash = this._hashStr(user);
    const index = hash % PROFILE_IMAGE_COLOURS.length;
    return PROFILE_IMAGE_COLOURS[index];
  }
}

window.customElements.define('profile-image', ProfileImage);
