import { css, html, LitElement } from 'lit';

import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog-confirm.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { tenantTypeIndicator } from '../helpers/helpers.js';

class PMEnvironment extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      _environment: { type: Object },
      _providers: { type: Array },
      _admins: { type: Array },
      _employers: { type: Array },
      _tenantToRemove: { type: Object },
      _branchNames: { type: Array },
      _branchName: { type: String },
      environmentId: { type: String, attribute: 'environment-id' },

    };
  }

  static get styles() {
    return [
      super.styles,
      heading3Styles,
      inputLabelStyles,
      inputStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }
        .d2l-heading-2 {
          padding-top: 0;
          margin-top: 0;
        }

        .tenant-content {
          height: 100%;
          align-self: center;
          display: grid;
          place-items: center;
        }

        .actions {
          align-items: center;
          display: block;
        }

        .tenant-list-heading {
          font-size: 19px;
        }
      `];
  }

  constructor() {
    super();
    this._environment = { tenants: [] };
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async updated() {
    if ((!this.environmentId && !this._environment.id) || !this.skeleton) return;
    const environment = this._environment.id ? this._environment : await this.client.fetchEnvironment(this.environmentId);
    if (environment?.status === 'ENV_NOT_SETUP') {
      this._environmentError = true;
      environment.tenants = [];
    }
    this._providers = environment.tenants.filter(env => env.type === 'provider');
    this._providers.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this._employers = environment.tenants.filter(env => env.type === 'employer');
    this._employers.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this._admins = environment.tenants.filter(env => env.type === 'admin');
    this._admins.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    environment.tenants = [...this._providers, ...this._employers, ...this._admins];

    environment.id = environment.id ?? this.environmentId;

    this._environment = environment;

    this.skeleton = false;
  }

  _tenantsListTemplate(list = this._environment.tenants) {
    // Empty tenant list template
    if (list.length === 0) {
      return html`
        <d2l-list-item label="Tenant content">
          <div class="tenant-content">No tenants in environment yet</div>
        </d2l-list-item>
      `;
    }
    // Existing tenants template
    return html`
      ${list.map(tenant => html`
        <d2l-list-item href="/tenants/${this._environment.id}/${tenant.id}" key="${tenant.id}" label="${tenant.displayName}">
          <profile-image .user="${tenant}" slot="illustration"></profile-image>
          <div class="actions" slot="actions">
            ${tenantTypeIndicator(tenant.type)}
          </div>
        </d2l-list-item>
      `)}`;
  }

  render() {
    return html`
      <d2l-list class="d2l-skeletize" extend-separators>
          <d2l-list-item expandable style="width: 100%;" key="providers" label="Providers">
              <d2l-list-item-content>
                <div class="tenant-list-heading">Providers</div>
              </d2l-list-item-content>
              <d2l-list slot="nested">
                  ${this._tenantsListTemplate(this._providers)}
              </d2l-list>
          </d2l-list-item>
          <d2l-list-item expandable style="width: 100%;"  key="employers" label="Employers">
              <d2l-list-item-content>
                <div class="tenant-list-heading">Employers</div>
              </d2l-list-item-content>
              <d2l-list slot="nested">
                  ${this._tenantsListTemplate(this._employers)}
              </d2l-list>
          </d2l-list-item>
          <d2l-list-item expandable style="width: 100%;"  key="admins" label="Admins">
              <d2l-list-item-content>
                <div class="tenant-list-heading">Admins</div>
              </d2l-list-item-content>
              <d2l-list slot="nested">
                  ${this._tenantsListTemplate(this._admins)}
              </d2l-list>
          </d2l-list-item>
      </d2l-list>
      `;
  }

}

window.customElements.define('pm-environment', PMEnvironment);
