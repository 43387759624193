import { css } from 'lit';

export const cardStyles = css`
    .card {
        height: fit-content;
        background-color: rgb(255, 255, 255);
        border: 1px solid var(--d2l-color-mica);
        border-radius: 6px;
    }

  .card-content {
    padding: 32px 20px;
  }
`;
