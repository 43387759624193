import {css, html, LitElement} from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';

import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/inputs/input-text';

import '../environment.js';

class FussStageConfig extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      environmentId: { type: String, attribute: 'environment-id' },
      _environment: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
          padding: 20px 0;
        }
        d2l-input-text {
          padding: 20px 0;
        }
        .url-wrapper {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 5px;
        }
        .https {
          padding-top: 55px;
        }
      `
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
    try {
      this._environment = await this.client.fetchEnvironment(this.environmentId);
    } catch(err) {
      this._environment = { id: this.environmentId };
    }
    this._environment.fussUrl = this._environment?.fussUrl?.replace('https://', '') || '';
  }

  async save() {
    const fussUrl = this._environment.fussUrl ? `https://${this._environment.fussUrl}` : '';
    const amplitudeProjectId = this._environment.amplitudeProjectId;
    await this.client.updateFussConfig(this.environmentId, { fussUrl, amplitudeProjectId });
  }

  handleFormChange(e) {
    this._environment = {
      ...this._environment,
      [e.target.id]: e.target.value
    };
  }

  async testConnection() {
    const response = await this.client.testFussConnection(this._environment.fussUrl);
    if(response.success) {
      this.client.createAlert('success', 'Connection successful');
    } else {
      this.client.createAlert('error', 'Connection failed');
    }
  }

  render() {
    const fussUrl = this._environment?.fussUrl || '';
    const amplitudeProjectId = this._environment?.amplitudeProjectId || '';
    return html`
      <d2l-form @change="${this.handleFormChange}">
        <d2l-input-text id="amplitudeProjectId" label="Amplitude Project ID" .value="${amplitudeProjectId}" required></d2l-input-text>
        <div class="url-wrapper">
            <span class="https">https://</span><d2l-input-text id="fussUrl" label="FUSS Url" .value="${fussUrl}" required></d2l-input-text>
        </div>
        <d2l-button @click="${this.testConnection}">Test Connection</d2l-button>
        <d2l-button type="button" primary @click="${this.save}">Save</d2l-button>
      </d2l-form>

      `;
  }
}

window.customElements.define('pm-fuss-stage-config', FussStageConfig);
