import {css, html, LitElement, nothing} from 'lit';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import {mapify, tenantTypeIndicator} from "../helpers/helpers.js";
import {repeat} from "lit/directives/repeat.js";

import '@brightspace-ui/core/components/tabs/tabs';
import '@brightspace-ui/core/components/tabs/tab-panel';

import '../components/fuss/fuss-user-list.js';
import '../components/fuss/fuss-sync-table.js';
import {selectStyles} from "@brightspace-ui/core/components/inputs/input-select-styles.js";

class PMFussTenant extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      environmentId: { type: String, attribute: 'environment-id' },
      tenantUsers: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      heading1Styles,
      css`
        :host {
          display: block;
        }
      `];
  }

  constructor() {
    super();
    this._tenant = {};
    this._newDomain = {};
    this.skeleton = true;
    this._domainsMap = [];
    this.tenantUsers = [];
    this.allUsers = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    this.tenantUsers = await this.client.fetchTenantUsers(this.environmentId, this.tenantId);
    this._tenant = await this.client.fetchTenantSSO(this.environmentId, this.tenantId);
    this._domain = (this._tenant.ssoDomains.find(x => x.default) || this._tenant.ssoDomains[0] || {}).ssoId;
    this._domainsMap = mapify(this._tenant.ssoDomains, 'ssoId');
    this._existingURLs = this._tenant.ssoDomains.map(domain => domain.domain);
    this.skeleton = false;
  }

  get currentDomain() {
    return this._domainsMap[this._domain] || {};
  }

  _changeDomain(e) {
    this._domain = e.target.value;
  }

  usersListTemplate() {
    if(!this._tenant?.ssoDomains) return nothing;
    return html`
    <h1 class="d2l-heading-1 d2l-skeletize">${this.environmentId} - ${this._tenant.displayName} - ${tenantTypeIndicator(this._tenant.type)}</h1>
    <label for="domain-select">Choose a domain:</label>
    <select
      id="domain-select"
      aria-label="Choose a domain:"
      value=${this._domain}
      @change=${this._changeDomain}
      class="d2l-input-select">
      ${repeat(this._tenant.ssoDomains, sso => sso.ssoId, sso => html`
          <option .selected=${sso.ssoId === this._domain} .value=${sso.ssoId}>${sso.domain}</option>
        `)}
    </select>
    <pm-fuss-users-list
      .users="${this.tenantUsers}"
      relay-state="${this.relayState}"
      tenant-id="${this.tenantId}"
      environment-id="${this.environmentId}"
      login-type="${this.currentDomain.loginType}"
      domain=${this.currentDomain.host}>
    </pm-fuss-users-list>
  `;
  }

  render() {
    return html`
      <d2l-tabs>
        <d2l-tab-panel text="Users">${this.usersListTemplate()}</d2l-tab-panel>
        <d2l-tab-panel text="Syncs">
          <pm-fuss-sync-table></pm-fuss-sync-table>
        </d2l-tab-panel>
      </d2l-tabs>
    `;
  }

}

window.customElements.define('pm-fuss-tenant', PMFussTenant);
